$('document').ready(function() {  
    var mySwiper = new Swiper('.swiper-container', {
        grabCursor: true,
        speed: 800,
        centeredSlides: true,
        slidesPerView: 'auto',
        slideShadows: true,
        loop: false,
        runCallbacksOnInit: false,
        initialSlide: 2,
        effect: 'coverflow',
        coverflowEffect: {
          rotate: 0,
          stretch: 190,
          depth: 2,
          modifier: 1
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        scrollbar: {
          el: '.swiper-scrollbar',
          draggable: false,
          dragSize: 51,
        }
      });

      
    mySwiper.on('slideChange', function () {
    // reviewsText.forEach(function(item, i, arr) {
    //     item.classList.remove('active');
    //   });

      for(var k = 0; k < reviewsText.length; k++) {
        reviewsText[k].classList.remove('active');
      }
  });
});

var reviewsToggle = function(evt) {
    if(evt.target.classList.contains('reviews__item--text-toggle')) {
        var b = evt.srcElement.offsetParent;
        if(b.classList.contains('active')) {
           b.classList.remove('active');
        } else {
            b.classList.add('active');
        }
    }
}

var reviewsText = document.querySelectorAll('.reviews__item');
for (var i = 0; i < reviewsText.length; i++) {
    var a = reviewsText[i];
    a.addEventListener("click", reviewsToggle)
}