function initSelect() {
	var inputSelect = $(".country-select").first(),
		customOptions = $(".options");
	let selected = $('.selected');
	let currentCountry = $('.country-select option:selected').first();
	let currentCountryCode = currentCountry.val();
	let selectedText = $('<span>', {
		class: 'option__text',
		text: currentCountry.text()
	});
	selected.addClass(currentCountryCode);
	selectedText.appendTo(selected);

	function setOptions(select) {
		inputSelect.find('option').each(function(i, option) {
			let countryCode = option.value;
			let countryName = option.text;
			let link = $('<a>', {
				class: countryCode + ' option',
				href: "?&country_code=".concat(option.value),
				'data-value': countryCode
			});
			let optionText = $('<span>', {
				class: 'option__text',
				text: countryName
			});
			optionText.appendTo(link);
			if (countryCode !== currentCountryCode) {
				link.appendTo(customOptions);
			} else {
				link.prependTo(customOptions);
			}
		})
	}
	setOptions(inputSelect);
	$(window).on('click', function(e) {
		var target = $(e.target);
		// if (target.hasClass('selected')) {
		if (target.hasClass('custom-select') || target.hasClass('selected')) {
			// target.addClass('hide');
			target.parent().find('.options').addClass('opened');
		} else {
			// $('.hide').removeClass('hide');
			$('.opened').removeClass('opened');
		}
	});
}
initSelect();